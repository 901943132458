import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appIndianNumberSystem]'
})
export class IndianNumberSystemDirective {

  constructor(private el: ElementRef) { }

  private formatNumber(value: string): string {
    const num = parseFloat(value.replace(/,/g, ''));
    if (isNaN(num)) {
      return value;
    }

    const [integerPart, decimalPart] = num.toString().split('.');
    const integerFormatted = this.indianFormat(integerPart);
    return decimalPart ? `${integerFormatted}.${decimalPart}` : integerFormatted;
  }

  private indianFormat(num: string): string {
    const lastThreeDigits = num.slice(-3);
    const otherDigits = num.slice(0, -3);

    const formattedOtherDigits = otherDigits
      .replace(/\B(?=(\d{2})+(?!\d))/g, ",")
      .concat(lastThreeDigits);

    return formattedOtherDigits;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    const formattedValue = this.formatNumber(value);
    this.el.nativeElement.value = formattedValue;
  }

}
