import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }

  /**
   * Retrieves the value associated with the specified key from the local storage.
   * @param key - The key to retrieve the value for.
   * @returns The value associated with the key, or null if the key does not exist.
   */
  getItem(key: string): any {
    const item = localStorage.getItem(key);
    if (item) {
        try {
            return JSON.parse(item);
        } catch (error) {
            localStorage.removeItem(key);
            return null;
        }
    }
    return null;
}


  /**
   * Sets the value associated with the specified key in the local storage.
   * @param key - The key to set the value for.
   * @param value - The value to be stored.
   */
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes the value associated with the specified key from the local storage.
   * @param key - The key to remove the value for.
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears all key-value pairs from the local storage.
   */
  clear(): void {
    localStorage.clear();
  }
}