import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianNumberSystem'
})
export class IndianNumberSystemPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }

    const num = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    if (isNaN(num)) {
      return value.toString();
    }

    const [integerPart, decimalPart] = num.toString().split('.');
    const integerFormatted = this.indianFormat(integerPart);
    return decimalPart ? `${integerFormatted}.${decimalPart}` : integerFormatted;
  }

  private indianFormat(num: string): string {
    const lastThreeDigits = num.slice(-3);
    const otherDigits = num.slice(0, -3);

    const formattedOtherDigits = otherDigits
      .replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    
    return formattedOtherDigits ? `${formattedOtherDigits},${lastThreeDigits}` : lastThreeDigits;
  }
}
