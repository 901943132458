export enum Module {
    MAIN_MODULE = 'DigiMRO-ERP',
    BOQ_CREATE = 'BOQ CREATION',
    BOQ_EDIT = 'BOQ EDIT',
    BOQ_DRAFT = 'BOQ DRAFT',
    BOQ_SEARCH = 'BOQ SEARCH',
    BOQ_APPROVAL = 'BOQ APPROVAL',
    ORDER_MODULE = 'ORDER MODULE',
    BOQ_MODULE = 'BOQ MODULE ACCESS',
    DASHBOARD = 'SALES PERSON DASHBOARD',
    SEARCH_ORDER = 'ORDER SEARCH',
    CREATE_ORDER = 'ORDER CREATION',
    CUSTOMERS = 'DIGI-CUSTOMERS',
    RMA_SUSPENSION = 'DIGI-RMA-SUSPENSION',
    DELIVERY_CHALLAN = 'DIGI-DELIVERY-CHALLAN',
    UPDATE_CUSTOMER_DETAILS = 'DIGI-UPDATE-CUSTOMER-DETAILS'
}