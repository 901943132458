import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerStatus'
})
export class CustomerStatusPipe implements PipeTransform {

  /**
   * Transforms a numeric status value into a human-readable status string.
   *
   * - If the status value is 50, it returns 'Active'.
   * - If the status value is 10, it returns 'Inactive'.
   * - If the status value is neither 50 nor 10, it returns 'Unknown'.
   *
   * @param value - The numeric status value to be transformed.
   * @param args - Additional arguments that are unused in this pipe.
   * @returns A string representing the customer status (either 'Active', 'Inactive', or 'Unknown').
   */
  transform(value: number, ...args: unknown[]): string {
    if (value === 50) {
      return 'Active';
    } else if (value === 10) {
      return 'Inactive';
    }
    return 'Unknown'; // Default value in case of an unrecognized status
  }
}