export const PAGE_SIZE = 50; // Default page size
export const NOTIFICATION_DISPLAY_DURATION = 3000; // Duration in milliseconds to display the notification
export const APPROVAL ={
    DEFAULT_GM_PERCENTAGE : 7,
    USERTYPE : {
        BM_UM: new Set([6, 22]),  // BM and UM
        RM_ZM: new Set([3, 23]),   // RM and ZM
        PMM: new Set([7]),
        APP_DESK: new Set([26]),
        Director: new Set([5]),
        GM: new Set([24]),
      },
    USERID:{
      BM:6,
      GM:24,
      DIRECTOR:5,
      PMM:7,
      APP_DESK:26,
      UM:22,
      RM:3,
      ZM:23
    },
    USERID_FIELD_DISABLED : [6, 22, 3, 23], // UM/RM/BM/ZM userId
    BUTTONS:{
      APPROVE_FORWARD_REJECT_BACKWARD: ['APPROVE', 'FORWARD', 'REJECT','BACKWARD'],
      APPROVE_FORWARD_REJECT: ['APPROVE', 'FORWARD', 'REJECT'],
      FORWARD_REJECT: ['FORWARD', 'REJECT'],
      APPROVE_PARTIALLY_FORWARD_PARTIALLY_REJECT: ['APPROVE_PARTIALLY', 'FORWARD_PARTIALLY', 'REJECT'],
      FORWARD_PARTIALLY_REJECT: ['FORWARD_PARTIALLY', 'REJECT'],
      APPROVE_REJECT_BACKWARD: ['APPROVE', 'REJECT', 'BACKWARD'],
      BACKWARD:'BACKWARD',
      APPROVE:'APPROVE',
      REJECT:'REJECT'
    },
    BOQ_TYPE:{
      FORWARD : 'FORWARD',
      BACKWARD : 'BACKWARD',
      APPROVE : 'APPROVE'
    }

    
}
export const ORDER = {
  STATUS:{
    DRAFT:'Draft',
    BOOKED:'Booked',
    CANCELLED:'Cancelled',
    BILLED:'Billed',
    BILLED_PARTIALLY:'Billed Partially',
    EXPIRED:'Expired',
    STOCK_REQUEST:'Stock_Request',
    STOCK_REQUEST_NOTAPPROVED:'Stock_Request_NotApproved',
    STOCK_REQUEST_REJECTED:'Stock_Request_Rejected',
    STOCK_REQUEST_PENDING_WITH_COMMERCIAL:'Stock Request Pending with Commercial'
  }
}

export const ITEM = {
  CLASSIFICATION:{
    S:'S',
    X:'X',
    U:'U',
    L:'L',
    H:'H',
    C:'C',
    B:'B'
  }
}