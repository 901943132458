import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessControlService } from '../../services/access-control.service';
import { AccountService } from 'src/app/core/services/account.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { APP_ROUTES } from 'src/app/core/constants/app-routes.constants';
import { SIDEBAR_MENU_CONFIG } from '../../config/sidebar-menu.config';
import { SidebarMenu } from 'src/app/shared/interfaces/sidebar-menu.interface';
import { AccessModule } from 'src/app/shared/interfaces/access-module.interface';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidebar', { static: true }) sidebar!: ElementRef;

  menus$: Observable<SidebarMenu[]> = new Observable<SidebarMenu[]>(); // Observable to hold the filtered sidebar menus

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.generateSidebarMenu();
  }

  generateSidebarMenu(): void {
    // Map the accessModules stream to generate the sidebar menu list dynamically
    this.menus$ = this.accessControlService.accessModules.pipe(
      map((modules: AccessModule[]) => {
        const moduleNames = modules.map(module => module.name);

        return SIDEBAR_MENU_CONFIG.filter(menu => {
          const hasModule = moduleNames.includes(menu.name);
          const hasSubmenus = menu.submenus?.some(submenu => moduleNames.includes(submenu.name));
          return hasModule || hasSubmenus;
        }).map(menu => {
          if (menu.submenus) {
            menu.submenus = menu.submenus.filter(submenu => moduleNames.includes(submenu.name));
          }
          return menu;
        });
      })
    );
  }

  // Toggles the visibility of the sidebar
  toggleSidebar(): void {
    this.sidebar.nativeElement.classList.toggle('close');
  }

  // Toggles the visibility of submenus within a menu
  toggleSubmenu(menu: SidebarMenu): void {
    menu.expanded = !menu.expanded;
  }

  // Expand the sidebar when the mouse hovers over it
  onMouseOver(): void {
    this.sidebar.nativeElement.classList.remove('close');
  }

  // Collapse the sidebar when the mouse leaves it
  onMouseOut(): void {
    this.sidebar.nativeElement.classList.add('close');
  }

  /**
   * Handles the logout process by calling the `AccountService` and navigating to the login page.
   *
   * - Calls the `logout` method on `AccountService` to perform the logout action.
   * - On success, clears the local storage and redirects the user to the login page.
   * - Logs an error to the console if the logout request fails.
   */
  handleLogout(): void {
    this.accountService.logout()
      .subscribe({
        next: (response) => {
          if (response.status === HttpStatusCode.Ok) {
            this.localStorageService.clear();
            this.router.navigateByUrl(APP_ROUTES.AUTH.LOGIN);
          } else {
            console.error('Logout failed:', response.status);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        }
      });
  }
}