import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MESSAGE } from '../constants/message.constants';
import { APP_ROUTES } from '../constants/app-routes.constants';

interface RequestOptions {
  responseType: 'json' | 'blob';
  observe: 'body' | 'response';
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) { }

  private handleError = (error: any): Observable<never> => {
    if (error.status === 401) {
      localStorage.removeItem('token');
      this.notificationService.showError(MESSAGE.ERROR.SESSION_EXPIRED);
    } else if (error.status === 300) {
      window.location.href = '.' + APP_ROUTES.AUTH.SELECT_USER;
    } else {
      this.notificationService.showError(MESSAGE.ERROR.SOMETHING_WENT_WRONG);
    }
    return throwError(() => error);
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  private createOptions(options?: RequestOptions) {
    const httpOptions: any = {
      headers: this.createHeaders(),
    };

    if (options?.responseType) {
      httpOptions.responseType = options.responseType;
    }

    if (options?.observe) {
      httpOptions.observe = options.observe;
    }

    return httpOptions;
  }

  get<T>(url: string, options?: RequestOptions): Observable<T | HttpResponse<T>> {
    return this.httpClient.get<T>(url, this.createOptions(options))
      .pipe(catchError(this.handleError)) as Observable<T | HttpResponse<T>>;
  }

  post<T>(url: string, body: any, options?: RequestOptions): Observable<T | HttpResponse<T>> {
    return this.httpClient.post<T>(url, body, this.createOptions(options))
      .pipe(catchError(this.handleError)) as Observable<T | HttpResponse<T>>;
  }

  put<T>(url: string, body: any, options?: RequestOptions): Observable<T | HttpResponse<T>> {
    return this.httpClient.put<T>(url, body, this.createOptions(options))
      .pipe(catchError(this.handleError)) as Observable<T | HttpResponse<T>>;
  }

  delete<T>(url: string, options: RequestOptions): Observable<T | HttpResponse<T>> {
    return this.httpClient.delete<T>(url, this.createOptions(options))
      .pipe(catchError(this.handleError)) as Observable<T | HttpResponse<T>>;
  }
  upload<T>(url: string, body: any): Observable<T | HttpResponse<T>> {
    return this.httpClient.post<T>(url, body)
      .pipe(catchError(this.handleError)) as Observable<T | HttpResponse<T>>;
  }
}
