import { Component, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-pie-chart',
  template: `<div id="{{ chartId }}" style="height: 400px; width: 100%;"></div>`,
})
export class PieChartComponent implements AfterViewInit,OnChanges {
  @Input() chartId: string = '';
  @Input() chartTitle: string = '';
  private _chartData: Array<any> = [];

  @Input()
  set chartData(value: Array<any>) {
    this._chartData = value;
  }

  constructor(private commonService: CommonService) {}

  ngAfterViewInit() {
    if (this._chartData.length > 0) {
      this.initChart();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartId'] && this.chartId) {
      setTimeout(() => this.initChart(), 0);
    }
    if (changes['chartData']) {
      setTimeout(() => this.initChart(), 0);
    }
  }

  initChart() {
    const options: Highcharts.Options = {
      chart: {
        type: 'pie',
        width: 580,
        marginLeft: 200, 
      },
      title: {
        text: '',
        align: 'left',
        style: this.commonService.commonTextStyle,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: this.commonService.commonLabelStyle,
          },
          showInLegend: true,
        },
      },
      legend: {
        layout: 'vertical', 
        align: 'left',
    },
      series: [{
        type: 'pie',
        colors: this.commonService.colorPalette,
        data: this._chartData,
      }],
    };

    const chartContainer = document.getElementById(this.chartId);
    if (chartContainer) {
      Highcharts.chart(this.chartId, options);
    } else {
      console.error(`Chart container with id ${this.chartId} not found.`);
      chartContainer!.innerHTML = '<div>No Data Found</div>';
    }
  }
}
