import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rmaStatus'
})
export class RmaStatusPipe implements PipeTransform {

  /**
   * Transforms a boolean value representing an RMA status into a string.
   *
   * - Returns 'Suspended' if the value is `true`.
   * - Returns 'Active' if the value is `false`.
   * - Returns 'Unknown' if the value is `null` or `undefined`.
   *
   * @param value - The boolean value indicating the RMA status or `null`/`undefined` for an unknown status.
   * @returns A string representing the RMA status.
   */
  transform(value: boolean | null): string {
    if (value === null || value === undefined) return 'Unknown';

    return value ? 'Suspended' : 'Active';
  }
}