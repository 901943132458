export const APP_ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    SELECT_USER: '/auth/select-user-type'
  },
  UNAUTHORIZED: '/unauthorized',
  SALES: '/sales',
  SUMMARY: '/summary',
  PERFORMANCE: '/performance',
  CUSTOMER: {
    LIST: '/customers',
    SUSPENSION: '/customers/suspension',
    REVOKE_SUSPENSION: '/customers/suspension/revoke',
    DELIVERY_CHALLAN: '/customers/delivery-challan',
    UPDATE_CUSTOMER_DETAILS: '/customers/update'
  },
  BOQ:{
    LISTING:'/boq/boq-list',
    CREATE:'/boq/create-boq',
    DRAFT:'/boq/draft-boq-list',
    APPROVAL:'/boq/approval',
    EDIT:'/boq/edit-boq-list',
    APPROVAL_DETAILS:'/boq/approval-list-details',
    SEARCH_DETAILS:'/boq/details',
    EDIT_BY_ID:'/boq/edit-boq',
    HISTORY:'/boq/history'
  }
};