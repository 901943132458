import { Component, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-column-chart',
  template: `<div id="{{ chartId }}" style="height: 400px; width: 100%;"></div>`,
})
export class ColumnChartComponent implements AfterViewInit,OnChanges {
  @Input() chartId: string = '';
  @Input() chartTitle: string = '';
  private _chartData: Array<any> = [];

  @Input()
  set chartData(value: Array<any>) {
    this._chartData = value;
  }

  constructor(private commonService: CommonService) {}

  ngAfterViewInit() {
    if (this._chartData?.length > 0) {
      this.initChart();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartId'] && this.chartId) {
      setTimeout(() => this.initChart(), 0);
    }
    if (changes['chartData']) {
      setTimeout(() => this.initChart(), 0);
    }
  }

  initChart() {
    const options: Highcharts.Options = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
        align: 'left',
        style: this.commonService.commonTextStyle,
      },
      xAxis: {
        type: 'category',
        labels: {
          autoRotation: [-45, -90],
          style: this.commonService.commonLabelStyle,
        },
      },
      yAxis: {
        min: 0,
        minTickInterval: 1,
        title: {
          text: 'No. of BOQs',
          style: this.commonService.commonTextStyle,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '<b>{point.y:.0f}</b>',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          borderWidth: 0,
          pointWidth: 35,
        },
        series: {
          marker: {
            enabled: true,
          },
        },
      },
      series: [{
        type: 'column',
        data: this._chartData,
        colorByPoint: true,
        colors: this.commonService.colorPalette,
        showInLegend: false,
      }]
    };
  
    const chartContainer = document.getElementById(this.chartId);
    if (chartContainer) {
      Highcharts.chart(this.chartId, options);
    } else {
      console.error(`Chart container with id ${this.chartId} not found.`);
      document.getElementById(this.chartId)!.innerHTML = '<div>No Data Found</div>';
    }
  }
  
}
