import { Module } from "src/app/shared/enums/modules.enum";
import { SidebarMenu } from "src/app/shared/interfaces/sidebar-menu.interface";
import { APP_ROUTES } from "../constants/app-routes.constants";

export const SIDEBAR_MENU_CONFIG: SidebarMenu[] = [
  {
    name: Module.DASHBOARD,
    displayName: 'Summary',
    iconClass: 'ri-dashboard-fill',
    routerLink: '/summary',
    hasSubmenus: false,
    submenus: []
  },

  {
    name: "BOQ",
    displayName: 'BOQ',
    iconClass: 'ri-file-list-2-fill',
    routerLink:APP_ROUTES.BOQ.CREATE,
    hasSubmenus: true,
    expanded: false,
    submenus: [
      {
        name: Module.BOQ_SEARCH,
        displayName: 'Search BOQ',
        iconClass: '',
        routerLink: APP_ROUTES.BOQ.LISTING,
        hasSubmenus: false,
        submenus: []
      },
      {
        name: Module.BOQ_CREATE,
        displayName: 'Create BOQ',
        iconClass: '',
        routerLink:APP_ROUTES.BOQ.CREATE,
        hasSubmenus: false,
        submenus: []
      },
      {
        name: Module.BOQ_DRAFT,
        displayName: 'Draft BOQ',
        iconClass: '',
        routerLink:APP_ROUTES.BOQ.DRAFT,
        hasSubmenus: false,
        submenus: []
      },
      {
        name: Module.BOQ_EDIT,
        displayName: 'Edit BOQ',
        iconClass: '',
        routerLink:APP_ROUTES.BOQ.EDIT,
        hasSubmenus: false,
        submenus: []
      },
      {
        name: Module.BOQ_APPROVAL,
        displayName: 'Approval',
        iconClass: '',
        routerLink:APP_ROUTES.BOQ.APPROVAL,
        hasSubmenus: false,
        submenus: []
      },
    ]
  },
  {
    name: "Order",
    displayName: 'Order',
    iconClass: 'ri-archive-fill',
    routerLink: '',
    hasSubmenus: true,
    expanded: false,
    submenus: [
      {
        name: Module.SEARCH_ORDER,
        displayName: 'Search Order',
        iconClass: '',
        routerLink: '/order/order-list',
        hasSubmenus: false,
        submenus: []
      },
      {
        name: Module.CREATE_ORDER,
        displayName: 'Create Order',
        iconClass: '',
        routerLink: '/order/create-order',
        hasSubmenus: false,
        submenus: []
      }
    ]
  },
  {
    name: Module.CUSTOMERS,
    displayName: 'Customers',
    iconClass: 'ri-group-fill',
    routerLink: '/customers',
    hasSubmenus: false,
    submenus: []
  },
];