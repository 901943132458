import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { GuestLayoutComponent } from './core/layouts/guest-layout/guest-layout.component';
import { MainDashboardLayoutComponent } from './core/layouts/main-dashboard-layout/main-dashboard-layout.component';
import { Module } from './shared/enums/modules.enum';
import { APP_ROUTES } from './core/constants/app-routes.constants';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth', component: GuestLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'summary', component: MainDashboardLayoutComponent,
    loadChildren: () => import('./features/summary/summary.module').then(m => m.SummaryModule),
    canActivate: [AuthGuard],
    data: { module: Module.DASHBOARD }
  },
  {
    path: 'boq', component: MainDashboardLayoutComponent,
    loadChildren: () => import('./features/boq/boq.module').then(m => m.BoqModule),
    // canActivate: [AuthGuard],
    data: { module: Module.BOQ_MODULE }
  },
  {
    path: 'order', component: MainDashboardLayoutComponent,
    loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule),
    // canActivate: [AuthGuard],
    data: { module: Module.ORDER_MODULE }
  },
  {
    path: 'customers',
    component: MainDashboardLayoutComponent,
    loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule),
  },
  { path: '**', redirectTo: APP_ROUTES.SUMMARY }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }