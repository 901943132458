import { Directive, ElementRef, HostListener, Input } from "@angular/core";
@Directive({
    selector: '[appDecimal]',
})
export class DecimalDirective {

    @Input() decimalDigits!: number;

    navigationKeys: Array<string> = [
        "delete",
        "backspace",
        "tab",
        "escape",
        "enter",
        ".",
        "arrowleft",
        "arrowright"
    ];

    constructor(private eleRef: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let key = e.key?.toLowerCase() || "";
        
        // Allow navigation keys and control shortcuts
        if (this.navigationKeys.includes(key) ||
            (key === 'a' && e.ctrlKey) || 
            (key === 'c' && e.ctrlKey) ||
            (key === 'v' && e.ctrlKey) || 
            (key === 'x' && e.ctrlKey) || 
            (key === 'a' && e.metaKey) || 
            (key === 'c' && e.metaKey) || 
            (key === 'v' && e.metaKey) || 
            (key === 'x' && e.metaKey)) {
            return; 
        }

        // Prevent space and non-numeric input
        if (e.key === ' ' || (!this.isValidKey(e.key))) {
            e.preventDefault();
            return;
        }

        const value: string = this.eleRef.nativeElement.value;
        if (value) {
            // Regex to match the input with decimals
            const pattern = `^\\d*(\\.\\d{0,${this.decimalDigits || 0}})?$`;
            const regex: RegExp = new RegExp(pattern);

            // Check if the value matches the regex after the potential input
            const newValue = value.slice(0, this.getCaretPosition()) + e.key + value.slice(this.getCaretPosition());
            if (!newValue.match(regex)) {
                e.preventDefault();
            }
        }
    }

    private isValidKey(key: string): boolean {
        return /^[0-9]$/.test(key) || key === '.';
    }

    private getCaretPosition(): number {
        const input: HTMLInputElement = this.eleRef.nativeElement;
        return input.selectionStart || 0; // Get the current caret position
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        if (event?.clipboardData) {
            let pastedInput: string = event.clipboardData
                .getData('text/plain')
                .replace(/[^0-9.]/g, '');
            pastedInput = parseFloat(pastedInput)?.toString();
            if (pastedInput) {
                document.execCommand('insertText', false, pastedInput);
            }
        }
    }
}