import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators'; // Import the debounceTime operator
import { LoaderService } from './core/services/loader.service';
import { Router } from '@angular/router';
import { AccountService } from './core/services/account.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { HttpStatusCode } from '@angular/common/http';
import { APP_ROUTES } from './core/constants/app-routes.constants';
import { HamburgerService } from './core/services/hamburger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string = 'DigiMRO | ERP';
  isLoading$: Observable<boolean>;
  userActivity: string | number | NodeJS.Timeout | undefined;
  userInactive: Subject<any> = new Subject();
  inactivityTimeout = 1800000; // 30 minutes in ms
  constructor(
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private hamburgerService:HamburgerService
  ) {
    this.isLoading$ = this.loaderService.getLoadingState();
    this.setSessionTimeOut();
    this.userInactive.subscribe(() => this.logOut());

    // Listen for changes in localStorage (across tabs)
    window.addEventListener('storage', this.handleStorageEvent.bind(this));
  }

  ngOnInit(): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.google_analytics.clientId}`;
    document.head.appendChild(script);

    script.onload = () => {
      const gtagScript = document.createElement('script');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.google_analytics.clientId}');
      `;
      document.head.appendChild(gtagScript);
    };
    
    // Initialize debounced refresh on mousemove using RxJS fromEvent and debounceTime
    this.listenForUserActivity();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // This will check if user is idle for 30 minutes
  setSessionTimeOut() {
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
      this.setInactiveStatusInLocalStorage();
    }, this.inactivityTimeout);
  }

  // This will listen for the mousemove event and debounce it using RxJS
  listenForUserActivity() {
    fromEvent(window, 'mousemove')
      .pipe(
        debounceTime(500) // 500 ms debounce time
      )
      .subscribe(() => {
        clearTimeout(this.userActivity); // Clear the existing timeout
        this.setSessionTimeOut(); // Set a new session timeout
        this.setActiveStatusInLocalStorage();
      });
  }

  // Set the active status in localStorage
  setActiveStatusInLocalStorage() {
    localStorage.setItem('userLastActive', new Date().toString());
  }

  // Set the inactive status in localStorage, which will trigger the storage event
  setInactiveStatusInLocalStorage() {
    localStorage.setItem('userLastActive', 'inactive');
  }

  // Handle storage event, triggered in other tabs
  handleStorageEvent(event: StorageEvent) {
    if (event.key === 'userLastActive' && event.newValue === 'inactive') {
      this.logOut();
    }
  }

  // Log out the user from all tabs
  logOut() {
    console.log('User logged out due to inactivity');
    this.accountService.logout()
      .subscribe({
        next: (response) => {
          if (response.status === HttpStatusCode.Ok) {
            this.localStorageService.clear();
            this.router.navigateByUrl(APP_ROUTES.AUTH.LOGIN);
          } else {
            console.error('Logout failed:', response.status);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        }
      });
  }

}
