import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AccessModule, ModuleAccess } from 'src/app/shared/interfaces/access-module.interface';
import { Module } from 'src/app/shared/enums/modules.enum';
import { MESSAGE } from '../constants/message.constants';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private accessModules$ = new BehaviorSubject<AccessModule[]>([]);
  private moduleAccess$ = new BehaviorSubject<ModuleAccess>({
    isAuthorized: false,
    isReadConfigured: false,
    isWriteConfigured: false
  });

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService
  ) { }

  /**
   * Returns an Observable that emits the user's access modules.
   * @returns An Observable that emits an array of AccessModule objects.
   */
  get accessModules(): Observable<AccessModule[]> {
    return this.accessModules$.asObservable();
  }

  /**
  * Returns an Observable that emits the current module access state.
  * @returns An Observable that emits a ModuleAccess object.
  */
  get moduleAccess(): Observable<ModuleAccess> {
    return this.moduleAccess$.asObservable();
  }

  /**
   * Retrieves the user's access modules from the account service.
   * @returns An Observable that emits an array of Module objects.
   */
  retrieveUserAccessModules(): any {
    const url: string = `${environment.microservices.accountServiceBaseUrl}${API_ENDPOINTS.ACCOUNT.MODULE_ACCESS.SUB_MODULE.LIST}?mainModuleName=${Module.MAIN_MODULE}`;
    this.apiService.get<any>(url).subscribe({
      next: (response: any) => {
        if (response.status === HttpStatusCode.Ok) {
          this.accessModules$.next(response.data)
          return of(true)
        } else {
          return of(false)
        }
      }
    })
  }

  /**
     * Checks if the current user has access to a specific module.
     * @param module - The name of the module to check access for.
     * @returns An Observable that emits a boolean indicating whether the user has access to the module.
     */
  hasModuleAccess(module: string): Observable<boolean> {
    const url = `${environment.microservices.accountServiceBaseUrl}${API_ENDPOINTS.ACCOUNT.USER.CHECK_MODULE_ACCESS}?moduleName=${module}`;
    return this.apiService.get<{ status: number, data: ModuleAccess }>(url).pipe(
      map((response: any) => {
        const { isAuthorized, isReadConfigured, isWriteConfigured } = response.data;
        this.moduleAccess$.next({ isAuthorized, isReadConfigured, isWriteConfigured });
        return isAuthorized;
      }),
      catchError(error => {
        console.error('Error checking module access', error);
        this.notificationService.showError(MESSAGE.ERROR.UNAUTHORIZED_USER_ERROR);
        return of(false);
      })
    );
  }

  checkWriteAccess(moduleAccess: ModuleAccess): boolean {
    return moduleAccess.isWriteConfigured;
  }

  getTooltipMessage(isWriteAccess: boolean): string {
    return isWriteAccess ? '' : MESSAGE.NO_WRITE_ACCESS;
  }
}