import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string = 'DigiMRO | ERP';
  isLoading$: Observable<boolean>;

  constructor(private loaderService: LoaderService,private changeDetector:ChangeDetectorRef) {
    this.isLoading$ = this.loaderService.getLoadingState();
  }

  ngOnInit(): void { }

  ngAfterViewChecked(){
    this.changeDetector.detectChanges();
  }
}